import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  LinearProgress,
} from "@mui/material";
import { db, auth, functions } from "../config/firebase"; // Adjust the import according to your project structure
import {
  collection,
  query,
  getDocs,
  orderBy as firestoreOrderBy,
  limit,
  startAfter,
  where,
  Timestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const CallLogs = () => {
  const [callLogs, setCallLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("recording_start_time");
  const [loading, setLoading] = useState(true);
  const [agencyAdmin, setAgencyAdmin] = useState(false);

  const fetchCallLogs = async () => {
    const getAgentCallLogs = httpsCallable(functions, "getAgentCallLogs");
    try {
      const result = await getAgentCallLogs({});
      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const _agentQuery = query(agentRef, where("uid", "==", user.uid));
      const _agentSnapshot = await getDocs(_agentQuery);
      const _agentDoc = _agentSnapshot.docs[0];
      setAgencyAdmin(_agentDoc.data().agency_admin ?? false);

      let agentUid = localStorage.getItem("agentUid");
      if (!agentUid) {
        agentUid = user.uid;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentUid", agentUid);
      }

      const agentQuery = query(agentRef, where("uid", "==", agentUid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];

      let agentId = localStorage.getItem("agentId");
      if (!agentId) {
        agentId = agentDoc.id;
        // if (process.env.NODE_ENV !== "production")
        localStorage.setItem("agentId", agentId);
      }

      if (agentSnapshot.empty) return;

      const agentData = agentDoc.data();

      // const callLogsRef = collection(db, `agents/${agentId}/transcribed_calls`);
      // const q = query(
      //   callLogsRef,
      //   firestoreOrderBy(orderBy, order),
      //   limit(rowsPerPage)
      // );
      // const querySnapshot = await getDocs(q);

      // const data = querySnapshot.docs.map((doc) => ({
      //   id: doc.id,
      //   ...doc.data(),
      // }));

      const _callLogs = await fetchCallLogs();

      setCallLogs(_callLogs);

      setLoading(false);
    };

    fetchData();
  }, [rowsPerPage, order, orderBy]);

  return (
    <div
      className="wrapper"
      style={{
        marginRight: "3em",
      }}
    >
      <h1>Call Logs</h1>
      <p>Only showing the call logs for the latest 100 picked up calls</p>
      <Paper>
        {loading ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Agent Phone Number</TableCell>
                    <TableCell>Call SID</TableCell>
                    <TableCell>Lead First Name</TableCell>
                    <TableCell>Lead State</TableCell>
                    <TableCell>Lead Phone Number</TableCell>
                    <TableCell>Call Start Time</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <LinearProgress />
          </>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Agent Phone Number</TableCell>
                  <TableCell>Call SID</TableCell>
                  <TableCell>Lead First Name</TableCell>
                  <TableCell>Lead State</TableCell>
                  <TableCell>Lead Phone Number</TableCell>
                  <TableCell>Call Start Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {callLogs.map((callLog) => (
                  <TableRow key={callLog.id}>
                    <TableCell>{callLog.agent_phone_number}</TableCell>
                    <TableCell>{callLog.call_sid}</TableCell>
                    <TableCell>{callLog.lead_first_name}</TableCell>
                    <TableCell>{callLog.lead_state}</TableCell>
                    <TableCell>{callLog.lead_phone_number}</TableCell>
                    <TableCell>
                      {new Date(
                        callLog.time_stamp._seconds * 1000
                      ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <br />
    </div>
  );
};

export default CallLogs;
