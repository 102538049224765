import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const TimeZoneSelect = ({ value, onChange }) => {
  const timeZones = [
    { label: "Mountain Time (MST)", value: "America/Denver" },
    { label: "Central Time (CST)", value: "America/Chicago" },
    { label: "Eastern Time (EST)", value: "America/New_York" },
    { label: "Pacific Time (PST)", value: "America/Los_Angeles" },
  ];

  return (
    <FormControl fullWidth margin="dense">
      <InputLabel>Time Zone</InputLabel>
      <Select value={value} onChange={onChange} label="Time Zone">
        {timeZones.map((tz) => (
          <MenuItem key={tz.value} value={tz.value}>
            {tz.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeZoneSelect;
