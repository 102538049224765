import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  IconButton,
  Box,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
  collectionGroup,
  getCountFromServer,
  getDocsFromServer,
  Timestamp,
  or,
  orderBy,
  addDoc,
  limit,
} from "firebase/firestore";
import { auth, db, functions } from "../config/firebase";
import {
  Add,
  ContentCopyOutlined,
  Edit,
  Expand,
  HdrPlus,
  Info,
  InfoOutlined,
  OpenInFull,
  PlusOneOutlined,
} from "@mui/icons-material";
import { httpsCallable } from "firebase/functions";
import { set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { AgentTableHead } from "../components/agency-admin/agent-table-head";

import "../styles/agency-admin.css";
import { AgentDetailsDialog } from "./agency-admin/agent-details-dialog";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import OnboardNewAgentDialog from "./home/onboard-new-agent-dialog";

export const AgencyAdmin = () => {
  const [agents, setAgents] = useState([]);
  const [totalActiveAgents, setTotalActiveAgents] = useState(0);
  const [totalDisabledAgents, setTotalDisabledAgents] = useState(0);
  const [totalDialsAgency, setTotalDialsAgency] = useState(0);
  const [totalPickUpsAgency, setTotalPickUpsAgency] = useState(0);
  const [totalLiveTransfersAgency, setTotalLiveTransfersAgency] = useState(0);
  const [totalAverageDialsPerPickUp, setTotalAverageDialsPerPickUp] =
    useState(0);
  const [
    totalAverageDialsPerLiveTransfer,
    setTotalAverageDialsPerLiveTransfer,
  ] = useState(0);
  const [
    totalAveragePickUpsPerLiveTransfer,
    setTotalAveragePickUpsPerLiveTransfer,
  ] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const [enablingAgentDialogOpen, setEnablingAgentDialogOpen] = useState(false);
  const [disablingAgentDialogOpen, setDisablingAgentDialogOpen] =
    useState(false);

  const [walletHistoryDialogOpen, setWalletHistoryDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addNewAdminDialogOpen, setAddNewAdminDialogOpen] = useState(false);
  const [onboardNewAgentDialogOpen, setOnboardNewAgentDialogOpen] =
    useState(false);
  const [testV2CallDialogOpen, setV2CallDialogOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [newFreeMinutes, setNewFreeMinutes] = useState("");
  const [newWalletBalance, setNewWalletBalance] = useState("");
  const [newWalletHistory, setNewWalletHistory] = useState([]);

  /**
   * New admin data
   */
  const [newAdminFirstName, setNewAdminFirstName] = useState("");
  const [newAdminLastName, setNewAdminLastName] = useState("");
  const [newAdminEmail, setNewAdminEmail] = useState("");

  const [testV2DropdownOption, setTestV2DropdownOption] = useState(
    "medicare_supp_cc_lt"
  );
  const [testV2FirstName, setTestV2FirstName] = useState("");
  const [testV2State, setTestV2State] = useState("");
  const [testV2FacebookPageName, setTestV2FacebookPageName] = useState("");
  const [testV2PhoneNumber, setTestV2PhoneNumber] = useState("");
  const [testV2LiveTransferPhoneNumber, setTestV2LiveTransferPhoneNumber] =
    useState("");

  const [loadingCurrentAgents, setLoadingCurrentAgents] = useState(true);
  const [loadingTotalDialsAgency, setLoadingTotalDialsAgency] = useState(true);
  const [
    loadingTotalAverageDialsToPickUp,
    setLoadingTotalAverageDialsToPickUp,
  ] = useState(true);
  const [
    loadingTotalAverageDialsToLiveTransfer,
    setLoadingTotalAverageDialsToLiveTransfer,
  ] = useState(true);
  const [
    loadingTotalAveragePickUpsToLiveTransfer,
    setLoadingTotalAveragePickUpsToLiveTransfer,
  ] = useState(true);
  const [loadingTotalPickUpsAgency, setLoadingTotalPickUpsAgency] =
    useState(true);
  const [loadingTotalLiveTransfersAgency, setLoadingTotalLiveTransfersAgency] =
    useState(true);
  const [loadingTotalLeads, setLoadingTotalLeads] = useState(true);
  const [loadingTotalActiveAgents, setLoadingTotalActiveAgents] =
    useState(true);

  const [agentDetailsDialogOpen, setAgentDetailsDialogOpen] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [loadingAgentDetails, setLoadingAgentDetails] = useState(false);

  const fetchTotalPickUps = async () => {
    const getTotalPickUpsAgency = httpsCallable(
      functions,
      "getTotalPickUpsAgency"
    );
    try {
      const result = await getTotalPickUpsAgency({});

      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const fetchTotalDials = async () => {
    const getTotalDialsAgency = httpsCallable(functions, "getTotalDialsAgency");
    try {
      const result = await getTotalDialsAgency({});

      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const fetchTotalLiveTransfers = async () => {
    const getTotalLiveTransfersAgency = httpsCallable(
      functions,
      "getTotalLiveTransfersAgency"
    );
    try {
      const result = await getTotalLiveTransfersAgency({});
      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const fetchTotalAverageDialsToPickUp = async () => {
    const getAverageDialsToPickUpAgency = httpsCallable(
      functions,
      "getAverageDialsToPickUpAgency"
    );
    try {
      const result = await getAverageDialsToPickUpAgency({});

      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const fetchTotalAverageDialsToLiveTransfer = async () => {
    const getAverageDialsToLiveTransferAgency = httpsCallable(
      functions,
      "getAverageDialsToLiveTransferAgency"
    );
    try {
      const result = await getAverageDialsToLiveTransferAgency({});

      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const fetchTotalAveragePickUpsToLiveTransfer = async () => {
    const getAveragePickUpsToLiveTransferAgency = httpsCallable(
      functions,
      "getAveragePickUpsToLiveTransferAgency"
    );
    try {
      const result = await getAveragePickUpsToLiveTransferAgency({});

      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const fetchAgents = async () => {
    const getAgents = httpsCallable(functions, "getAgents");
    try {
      const result = await getAgents({});
      setLoadingCurrentAgents(false);
      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const enableAgentAccount = async (agentUid) => {
    const _enableAgentAccount = httpsCallable(functions, "enableAgentAccount");
    try {
      const result = await _enableAgentAccount({
        agentUid: agentUid,
      });
      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  const disableAgentAccount = async (agentUid) => {
    const _disableAgentAccount = httpsCallable(
      functions,
      "disableAgentAccount"
    );
    try {
      const result = await _disableAgentAccount({
        agentUid: agentUid,
      });
      return result.data;
    } catch (error) {
      console.error("Error fetching total dials:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const agentsList = await fetchAgents();

      console.dir(agentsList);

      setAgents(agentsList);
      setTotalActiveAgents(
        agentsList.filter((agent) => !agent.disabled).length
      );
      setTotalDisabledAgents(
        agentsList.filter((agent) => agent.disabled).length
      );
      setLoadingTotalActiveAgents(false);

      const leadsCollection = collectionGroup(db, "leads");
      const firstDayOfMonth = new Date();
      firstDayOfMonth.setDate(1);
      firstDayOfMonth.setHours(8, 0, 0, 0);

      const leadsSnapshot = await getCountFromServer(leadsCollection);

      setTotalLeads(leadsSnapshot.data().count);

      setLoadingTotalLeads(false);

      const [
        _totalAgencyDials,
        _totalAverageDialsToPickUp,
        _totalPickUpsAgency,
        _totalAverageDialsToLiveTransfer,
        _totalAveragePickUpsToLiveTransfer,
        _totalLiveTransfersAgency,
      ] = await Promise.all([
        fetchTotalDials(),
        fetchTotalAverageDialsToPickUp(),
        fetchTotalPickUps(),
        fetchTotalAverageDialsToLiveTransfer(),
        fetchTotalAveragePickUpsToLiveTransfer(),
        fetchTotalLiveTransfers(),
      ]);

      setTotalDialsAgency(_totalAgencyDials);
      setTotalPickUpsAgency(_totalPickUpsAgency);
      setTotalAverageDialsPerPickUp(_totalAverageDialsToPickUp);
      setTotalAverageDialsPerLiveTransfer(_totalAverageDialsToLiveTransfer);
      setTotalAveragePickUpsPerLiveTransfer(_totalAveragePickUpsToLiveTransfer);
      setTotalLiveTransfersAgency(_totalLiveTransfersAgency);

      setLoadingTotalPickUpsAgency(false);
      setLoadingTotalDialsAgency(false);
      setLoadingTotalAverageDialsToPickUp(false);
      setLoadingTotalAverageDialsToLiveTransfer(false);
      setLoadingTotalAveragePickUpsToLiveTransfer(false);
      setLoadingTotalLiveTransfersAgency(false);
    };

    fetchData();
  }, []);

  const handleUpdateAgent = async (agentId, field, value) => {
    const db = getFirestore();
    const agentDocRef = doc(db, "agents", agentId);

    if (field === "free_minutes") {
      const agentFreeMinuteUpdateHistoryCollection = collection(
        db,
        `agents/${agentId}/free_minute_updates`
      );
      await Promise.all([
        updateDoc(agentDocRef, {
          [field]: value,
          last_free_minutes_update: Timestamp.now(),
        }),
        addDoc(agentFreeMinuteUpdateHistoryCollection, {
          previous_free_minutes: selectedAgent.free_minutes,
          new_free_minutes: value,
          updated_at: Timestamp.now(),
          updated_by: auth.currentUser.uid,
        }),
      ]);
    } else {
      await updateDoc(agentDocRef, { [field]: value });
    }

    setAgents((prevAgents) =>
      prevAgents.map((agent) =>
        agent.id === agentId ? { ...agent, [field]: value } : agent
      )
    );
  };

  const numberWithCommas = (x) => {
    if (x >= 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  };

  const handleOpenEnablingAgentDialog = () => {
    setEnablingAgentDialogOpen(true);
  };

  const handleCloseEnablingAgentDialog = () => {
    setEnablingAgentDialogOpen(false);
  };

  const handleOpenDisablingAgentDialog = () => {
    setDisablingAgentDialogOpen(true);
  };

  const handleCloseDisablingAgentDialog = () => {
    setDisablingAgentDialogOpen(false);
  };

  const handleOpenDialog = (agent) => {
    setSelectedAgent(agent);
    setNewFreeMinutes(agent.free_minutes);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAgent(null);
    setNewFreeMinutes("");
  };

  const handleOpenWalletDialog = (agent) => {
    setSelectedAgent(agent);
    setNewWalletBalance(agent.wallet_balance);
    setWalletDialogOpen(true);
  };

  const handleCloseWalletDialog = () => {
    setWalletDialogOpen(false);
    setSelectedAgent(null);
    setNewWalletBalance("");
  };

  const handleOpenWalletHistoryDialog = async (agent) => {
    setSelectedAgent(agent);

    const db = getFirestore();
    const walletHistoryCollection = query(
      collection(db, `agents/${agent.id}/wallet_transactions`),
      orderBy("created_at", "desc")
    );
    const walletHistorySnapshot = await getDocsFromServer(
      walletHistoryCollection
    );

    const walletHistory = walletHistorySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setNewWalletHistory(walletHistory);
    setWalletHistoryDialogOpen(true);
  };

  const handleCloseWalletHistoryDialog = () => {
    setWalletHistoryDialogOpen(false);
    setSelectedAgent(null);
    setNewWalletHistory([]);
  };

  const handleOpenAddNewAdminDialog = () => {
    setAddNewAdminDialogOpen(true);
  };

  const handleCloseAddNewAdminDialog = () => {
    setAddNewAdminDialogOpen(false);
    setNewAdminFirstName("");
    setNewAdminLastName("");
    setNewAdminEmail("");
  };

  const handleCloseTestV2Dialog = () => {
    setV2CallDialogOpen(false);
    setTestV2FirstName("");
    setTestV2PhoneNumber("");
    setTestV2State("");
  };

  const handleCloseOnboardNewAgentDialog = () => {
    setOnboardNewAgentDialogOpen(false);
  };

  const handleOpenAgentDetailsDialog = async (agentId) => {
    setLoadingAgentDetails(true);
    setAgentDetailsDialogOpen(true);

    try {
      const getAgentDetails = httpsCallable(functions, "getAgentStatistics");
      const result = await getAgentDetails({ agent_id: agentId });
      setAgentDetails(result.data);
    } catch (error) {
      console.error("Error fetching agent details:", error);
    } finally {
      setLoadingAgentDetails(false);
    }
  };

  const handleCloseAgentDetailsDialog = () => {
    setAgentDetailsDialogOpen(false);
    setAgentDetails(null);
  };

  return (
    <div style={{ marginRight: "4em" }} className="wrapper">
      <div style={{ height: "2em" }} />
      <Typography variant="h4" gutterBottom>
        Agency Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">
                {totalActiveAgents + totalDisabledAgents} Agents
              </Typography>
              {!loadingTotalActiveAgents ? (
                <>
                  <Typography variant="h4">
                    {totalActiveAgents} active
                    <br />
                    {totalDisabledAgents} disabled
                  </Typography>
                  {/* <Typography variant="h5">
                    Disabled: {totalDisabledAgents}
                  </Typography> */}
                </>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={1.5}>
          <Card>
            <CardContent>
              <Typography variant="h6">Disabled Agents</Typography>
              {!loadingTotalActiveAgents ? (
                <Typography variant="h4">{totalDisabledAgents}</Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">Total Agency Leads</Typography>
              {!loadingTotalLeads ? (
                <Typography variant="h4">
                  {numberWithCommas(totalLeads)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">Total Agency Dials</Typography>
              {!loadingTotalDialsAgency ? (
                <Typography variant="h4">
                  {numberWithCommas(totalDialsAgency)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">
                Total Agency Average Dials to Pick Up
              </Typography>
              {!loadingTotalAverageDialsToPickUp ? (
                <Typography variant="h4">
                  {totalAverageDialsPerPickUp.toFixed(2)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">
                Total Agency Average Dials to Live Transfer
              </Typography>
              {!loadingTotalAverageDialsToLiveTransfer ? (
                <Typography variant="h4">
                  {totalAverageDialsPerLiveTransfer.toFixed(2)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">
                Total Agency Average Pick Ups to Live Transfer
              </Typography>
              {!loadingTotalAveragePickUpsToLiveTransfer ? (
                <Typography variant="h4">
                  {totalAveragePickUpsPerLiveTransfer.toFixed(2)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">Total Agency Picked Up Calls</Typography>
              {!loadingTotalPickUpsAgency ? (
                <Typography variant="h4">
                  {numberWithCommas(totalPickUpsAgency)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6">Total Agency Live Transfers</Typography>
              {!loadingTotalLiveTransfersAgency ? (
                <Typography variant="h4">
                  {numberWithCommas(totalLiveTransfersAgency)}
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      height: "1em",
                    }}
                  />
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4" gutterBottom>
              Agents
            </Typography>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setV2CallDialogOpen(true)}
                style={{ marginLeft: "1rem" }}
              >
                Test v2 (James) call
                <Add />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddNewAdminDialogOpen(true)}
                style={{ marginLeft: "1rem" }}
              >
                Add New Agency Admin
                <Add />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOnboardNewAgentDialogOpen(true)}
                style={{ marginLeft: "1rem" }}
              >
                Onboard New Agent
                <Add />
              </Button>
            </div>
          </Box>
          {!loadingCurrentAgents ? (
            <TableContainer component={Paper}>
              <Table>
                <AgentTableHead />
                <TableBody>
                  {agents.map((agent) => (
                    <TableRow
                      className={agent.disabled ? "disabled-agent-row" : ""}
                      key={agent.id}
                    >
                      <TableCell>
                        <Typography variant="h6">
                          {agent.first_name} {agent.last_name}
                        </Typography>

                        <Typography
                          variant="h7"
                          className="enable-agent-account-text"
                          onClick={async () => {
                            if (agent.disabled === false) {
                              handleOpenDisablingAgentDialog();
                              let disabledAgentResult =
                                await disableAgentAccount(agent.uid);
                              handleCloseDisablingAgentDialog();
                              if (disabledAgentResult === true) {
                                setAgents((prevAgents) =>
                                  prevAgents.map((prevAgent) =>
                                    prevAgent.uid === agent.uid
                                      ? { ...prevAgent, disabled: true }
                                      : prevAgent
                                  )
                                );
                              } else {
                                alert(
                                  "Error enabling agent account. Please try again later."
                                );
                              }
                            } else {
                              handleOpenEnablingAgentDialog();
                              let enabledAgentResult = await enableAgentAccount(
                                agent.uid
                              );
                              handleCloseEnablingAgentDialog();
                              if (enabledAgentResult === true) {
                                setAgents((prevAgents) =>
                                  prevAgents.map((prevAgent) =>
                                    prevAgent.uid === agent.uid
                                      ? { ...prevAgent, disabled: false }
                                      : prevAgent
                                  )
                                );
                              } else {
                                alert(
                                  "Error enabling agent account. Please try again later."
                                );
                              }
                            }
                          }}
                        >
                          {agent.disabled
                            ? "Enable agent account"
                            : "Disable agent account"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">{agent.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box display="block">
                          <Box display="flex" alignItems="center">
                            <Typography variant="h6">
                              {numberWithCommas(agent.free_minutes)}
                            </Typography>
                            <IconButton
                              color="primary"
                              variant="contained"
                              onClick={() => handleOpenDialog(agent)}
                            >
                              <Edit />
                            </IconButton>
                          </Box>
                          {agent.last_free_minutes_update ? (
                            <Typography variant="h7">
                              Last updated on{" "}
                              {new Date(
                                agent.last_free_minutes_update._seconds * 1000
                              ).toLocaleString()}
                              {/* {new Date(
                              agent.last_free_minutes_update.seconds
                            ).toLocaleString()} */}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={agent.v2 == true ? "v2" : "v1"}
                          onChange={(e) =>
                            handleUpdateAgent(
                              agent.id,
                              "v2",
                              e.target.value == "v2" ? true : false
                            )
                          }
                        >
                          <MenuItem value="v1">V1</MenuItem>
                          <MenuItem value="v2">V2</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Box display="block">
                          <Box display="flex" alignItems="center">
                            <Typography variant="h6">
                              {numberWithCommas(agent.total_leads)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={agent.dialing_speed ?? "slow"}
                          onChange={(e) =>
                            handleUpdateAgent(
                              agent.id,
                              "dialing_speed",
                              e.target.value
                            )
                          }
                        >
                          <MenuItem value="very_slow">Very Slow</MenuItem>
                          <MenuItem value="slow">Slow</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="fast">Fast</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Box display="block">
                          <Box display="flex" alignItems="center">
                            <Typography variant="h6">
                              ${agent.wallet_balance.toFixed(2)}
                            </Typography>
                            {/* <IconButton
                            color="primary"
                            variant="contained"
                            onClick={() => handleOpenWalletDialog(agent)}
                          >
                            <Edit />
                          </IconButton> */}
                            <IconButton
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                handleOpenWalletHistoryDialog(agent)
                              }
                            >
                              <InfoOutlined />
                            </IconButton>
                          </Box>
                          {agent.last_recharge_time ? (
                            <Typography variant="h7">
                              Last recharged on{" "}
                              {new Date(
                                agent.last_recharge_time._seconds * 1000
                              ).toLocaleString()}
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h6">
                            {agent.stripe_customer_id}
                          </Typography>
                          <IconButton
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              /**
                               * Copy the Stripe Customer ID to the clipboard
                               * then show a success message
                               */
                              navigator.clipboard.writeText(
                                agent.stripe_customer_id
                              );
                              alert("Stripe Customer ID copied to clipboard!");
                            }}
                          >
                            <ContentCopyOutlined />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenAgentDetailsDialog(agent.id)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <AgentTableHead />
                </Table>
              </TableContainer>
              <LinearProgress />
            </>
          )}
        </Grid>
      </Grid>
      <AgentDetailsDialog
        agentDetailsDialogOpen={agentDetailsDialogOpen}
        handleCloseAgentDetailsDialog={handleCloseAgentDetailsDialog}
        loadingAgentDetails={loadingAgentDetails}
        agentDetails={agentDetails}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Update Free Minutes</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Free Minutes"
            type="number"
            fullWidth
            value={newFreeMinutes}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && Number(value) >= 0) {
                setNewFreeMinutes(value);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleUpdateAgent(
                selectedAgent.id,
                "free_minutes",
                parseInt(newFreeMinutes, 10)
              );
              handleCloseDialog();
            }}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={walletDialogOpen} onClose={handleCloseWalletDialog}>
        <DialogTitle>Update Wallet Balance</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Wallet Balance"
            type="number"
            fullWidth
            value={newWalletBalance}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && Number(value) >= 0) {
                setNewWalletBalance(value);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWalletDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleUpdateAgent(
                selectedAgent.id,
                "wallet_balance",
                parseFloat(newWalletBalance)
              );
              handleCloseWalletDialog();
            }}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={enablingAgentDialogOpen}>
        <DialogTitle>Enabling Agent Account</DialogTitle>
        <DialogContent>
          <center>
            <CircularProgress />
          </center>
        </DialogContent>
      </Dialog>
      <Dialog open={disablingAgentDialogOpen}>
        <DialogTitle>Disabling Agent Account</DialogTitle>
        <DialogContent>
          <center>
            <CircularProgress />
          </center>
        </DialogContent>
      </Dialog>
      <Dialog
        open={addNewAdminDialogOpen}
        onClose={handleCloseAddNewAdminDialog}
      >
        <DialogTitle>Add New Agency Admin</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            value={newAdminFirstName}
            onChange={(e) => setNewAdminFirstName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            value={newAdminLastName}
            onChange={(e) => setNewAdminLastName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={newAdminEmail}
            onChange={(e) => setNewAdminEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddNewAdminDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              const user = auth.currentUser;

              const createAgencyAdminAccount = httpsCallable(
                functions,
                "createAgencyAdminAccount"
              );

              const agentQuery = query(
                collection(db, "agents"),
                where("uid", "==", user.uid),
                limit(1)
              );
              const agentDoc = await getDocs(agentQuery);

              if (agentDoc.empty) {
                alert(
                  "You are not allowed to perform this action, please contact support"
                );
                return;
              }

              const agentData = agentDoc.docs[0].data();
              const agencyRef = agentData.agency_ref;
              const agencyId = agencyRef.id;

              if (agentData.agency_admin !== true) {
                alert(
                  "You are not allowed to perform this action, please contact support"
                );
                return;
              }

              await createAgencyAdminAccount({
                first_name: newAdminFirstName,
                last_name: newAdminLastName,
                email: newAdminEmail,
                agency_id: agencyId,
              })
                .then(() => {
                  handleCloseAddNewAdminDialog();
                  alert(
                    "New admin added successfully! Their password is Ch@ng3m3"
                  );
                })
                .catch((error) => {
                  handleCloseAddNewAdminDialog();
                  alert("Error adding new admin, please try again");
                });
            }}
            color="primary"
          >
            Add Admin
          </Button>
        </DialogActions>
      </Dialog>
      <OnboardNewAgentDialog
        open={onboardNewAgentDialogOpen}
        handleClose={handleCloseOnboardNewAgentDialog}
      ></OnboardNewAgentDialog>
      <Dialog open={testV2CallDialogOpen} onClose={handleCloseTestV2Dialog}>
        <DialogTitle>Test V2 Call</DialogTitle>
        <DialogContent>
          <Select
            value={testV2DropdownOption}
            onChange={(e) => {
              setTestV2DropdownOption(e.target.value);
            }}
          >
            <MenuItem value="medicare_supp_cc_lt">
              Medicare Artificial Voice Leads
            </MenuItem>
            <MenuItem value="medicare_supp_fb_lt">
              Medicare Facebook Leads Live Transfer
            </MenuItem>
            <MenuItem value="medicare_supp_t65_fb_lt">
              Medicare T65 Facebook Leads Live Transfer
            </MenuItem>
            <MenuItem value="mapd_fb_lt">
              MAPD Facebook Leads Live Transfer
            </MenuItem>
            <MenuItem value="mapd_fb_appointments">
              MAPD Facebook Leads Appointments
            </MenuItem>
            <MenuItem value="final_expense_fb_lt">
              Final Expense Facebook Leads Live Transfers
            </MenuItem>
            <MenuItem value="final_expense_fb_appointments">
              Final Expense Facebook Leads Appointments
            </MenuItem>
            <MenuItem value="final_expense_cc_lt">
              Final Expense Artificial Voice Leads
            </MenuItem>
            <MenuItem value="affordable_care_act_cc_lt">
              ACA Artificial Voice Leads
            </MenuItem>
          </Select>
          {testV2DropdownOption == "medicare_supp_cc_lt" ||
          testV2DropdownOption == "final_expense_cc_lt" ||
          testV2DropdownOption == "affordable_care_act_cc_lt" ? (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                value={testV2FirstName}
                onChange={(e) => setTestV2FirstName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="State"
                type="text"
                fullWidth
                value={testV2State}
                onChange={(e) => setTestV2State(e.target.value)}
              />
            </>
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                value={testV2FirstName}
                onChange={(e) => setTestV2FirstName(e.target.value)}
              />
              <TextField
                autoFocus
                margin="dense"
                label="Facebook Page Name"
                type="text"
                fullWidth
                value={testV2FacebookPageName}
                onChange={(e) => setTestV2FacebookPageName(e.target.value)}
              />
              <TextField
                margin="dense"
                label="State"
                type="text"
                fullWidth
                value={testV2State}
                onChange={(e) => setTestV2State(e.target.value)}
              />
            </>
          )}

          <p>
            <b>Live transfer phone number</b>
          </p>
          <PhoneInput
            country={"us"}
            value={testV2LiveTransferPhoneNumber}
            disableDropdown={true}
            disableCountryGuess={true}
            onChange={setTestV2LiveTransferPhoneNumber}
            enableLongNumbers={true}
            inputStyle={{
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              height: "56px",
              borderRadius: "4px",
              border: "1px solid #ced4da",
            }}
            required
          />
          <p>
            <b>Your phone number</b>
          </p>
          <PhoneInput
            country={"us"}
            value={testV2PhoneNumber}
            disableDropdown={true}
            disableCountryGuess={true}
            onChange={setTestV2PhoneNumber}
            enableLongNumbers={true}
            inputStyle={{
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              height: "56px",
              borderRadius: "4px",
              border: "1px solid #ced4da",
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTestV2Dialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              const user = auth.currentUser;

              const agentRef = collection(db, "agents");
              const _agentQuery = query(agentRef, where("uid", "==", user.uid));
              const _agentSnapshot = await getDocs(_agentQuery);
              const _agentDoc = _agentSnapshot.docs[0];

              const agentId = _agentDoc.id;
              const human_agent_first_name = _agentDoc.data()["first_name"];

              let lead_first_name = testV2FirstName;
              let lead_state = testV2State;
              let human_agent_company_name =
                testV2FacebookPageName == ""
                  ? "Senior Services"
                  : testV2FacebookPageName;
              let human_agent_number = `+${testV2LiveTransferPhoneNumber}`;
              let human_agent_id = agentId;
              let lead_type = testV2DropdownOption;
              let lead_id = "Fqm7c6sCzUD6CvXD6Guh";
              let lead_tz = "America/Chicago";
              let human_agent_tz = _agentDoc.data()["time_zone"];
              let to_number = testV2PhoneNumber;
              let agent_id = "agent_01b2663eaef5ae0af96f34cf22";
              await axios.post(
                `https://initiateretellcall-655075094783.us-central1.run.app/?lead_id=${lead_id}&lead_first_name=${lead_first_name}&from_number=12392081459&agent_id=${agent_id}&human_agent_id=${human_agent_id}&human_agent_company_name=${human_agent_company_name}&to_number=+${to_number}&lead_state=${lead_state}&human_agent_number=${human_agent_number}&human_agent_first_name=${human_agent_first_name}&lead_type=${lead_type}&human_agent_tz=${human_agent_tz}&lead_tz=${lead_tz}`
              );
              handleCloseTestV2Dialog();
            }}
            color="primary"
          >
            Test Call
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={walletHistoryDialogOpen}
        onClose={handleCloseWalletHistoryDialog}
      >
        <DialogTitle>Wallet transaction history</DialogTitle>
        <DialogContent>
          {newWalletHistory.map((transaction) => (
            <Box
              key={transaction.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{
                padding: "1rem",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginBottom: "1rem",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color: transaction.amount > 0 ? "green" : "red",
                }}
              >
                <p>
                  Previous balance: ${transaction.previous_balance.toFixed(2)}{" "}
                  before recharge
                </p>
                {transaction.amount > 0 ? "Recharged" : "Deducted"} $
                {Math.abs(transaction.amount)} on{" "}
                {new Date(
                  transaction.created_at.seconds * 1000
                ).toLocaleString()}
              </Typography>
            </Box>
          ))}
          {/* <TextField
            autoFocus
            margin="dense"
            label="Wallet Balance"
            type="number"
            fullWidth
            value={newWalletBalance}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && Number(value) >= 0) {
                setNewWalletBalance(value);
              }
            }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWalletHistoryDialog} color="primary">
            Cancel
          </Button>
          {/* <Button
            onClick={() => {
              handleUpdateAgent(
                selectedAgent.id,
                "wallet_balance",
                parseFloat(newWalletBalance)
              );
              handleCloseWalletDialog();
            }}
            color="primary"
          >
            Update
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
