import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import TimeZoneSelect from "../../components/agency-admin/time-zone-select";
import PhoneInput from "react-phone-input-2";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/firebase";

const OnboardNewAgentDialog = ({ open, handleClose }) => {
  const [newAgentFirstName, setNewAgentFirstName] = useState("");
  const [newAgentLastName, setNewAgentLastName] = useState("");
  const [newAgentEmail, setNewAgentEmail] = useState("");
  const [newAgentTwilioCallingNumber, setNewAgentTwilioCallingNumber] =
    useState("");
  const [newAgentLiveTransferNumber, setNewAgentLiveTransferNumber] =
    useState("");
  const [newAgentTwilioAccountSID, setNewAgentTwilioAccountSID] = useState("");
  const [newAgentTwilioAuthToken, setNewAgentTwilioAuthToken] = useState("");
  const [newAgentGHLLocationID, setNewAgentGHLLocationID] = useState("");
  const [newAgentGHLCalendarID, setNewAgentGHLCalendarID] = useState("");
  const [newAgentGHLAPIKey, setNewAgentGHLAPIKey] = useState("");
  const [newAgentTimeZone, setNewAgentTimeZone] = useState("America/Denver");
  const [newAgentInboundWebhook, setNewAgentInboundWebhook] = useState("");

  const createNewAgent = async ({
    first_name,
    last_name,
    email,
    twilio_calling_number,
    twilio_account_sid,
    twilio_auth_token,
    ghl_location_id,
    ghl_calendar_id,
    ghl_api_key,
    time_zone,
    inbound_webhook,
  }) => {
    console.log(first_name);
    console.log(last_name);
    console.log(email);
    console.log(twilio_calling_number);
    console.log(twilio_account_sid);
    console.log(twilio_auth_token);
    console.log(ghl_location_id);
    console.log(ghl_calendar_id);
    console.log(ghl_api_key);
    console.log(time_zone);
    console.log(inbound_webhook);
  };

  const handleAddAgent = async () => {
    const onboardNewAgentFromAdminDashboardCF = httpsCallable(
      functions,
      "onboardNewAgentFromAdminDashboard"
    );

    try {
      const result = await onboardNewAgentFromAdminDashboardCF({
        first_name: newAgentFirstName,
        last_name: newAgentLastName,
        email: newAgentEmail,
        agent_live_transfer_number: `+${newAgentLiveTransferNumber}`,
        twilio_calling_number: `+${newAgentTwilioCallingNumber}`,
        twilio_account_sid: newAgentTwilioAccountSID,
        twilio_auth_token: newAgentTwilioAuthToken,
        ghl_location_id: newAgentGHLLocationID,
        ghl_calendar_id: newAgentGHLCalendarID,
        ghl_api_key: newAgentGHLAPIKey,
        time_zone: newAgentTimeZone,
        inbound_webhook: newAgentInboundWebhook,
      });

      if (result.data == true) {
        handleClose();
        alert("New agent onboarded successfully!");
        window.location.reload();
      }
    } catch (error) {
      alert(
        "There was an error onboarding the agent. Please contact tech support"
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Agent</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="First Name"
          type="text"
          fullWidth
          value={newAgentFirstName}
          onChange={(e) => setNewAgentFirstName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Last Name"
          type="text"
          fullWidth
          value={newAgentLastName}
          onChange={(e) => setNewAgentLastName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={newAgentEmail}
          onChange={(e) => setNewAgentEmail(e.target.value)}
        />
        <PhoneInput
          country={"us"}
          value={newAgentTwilioCallingNumber}
          disableDropdown={true}
          disableCountryGuess={true}
          onChange={setNewAgentTwilioCallingNumber}
          enableLongNumbers={true}
          specialLabel="Twilio calling number"
          inputStyle={{
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            height: "56px",
            borderRadius: "4px",
            border: "1px solid #ced4da",
          }}
          required
        />
        <PhoneInput
          country={"us"}
          value={newAgentLiveTransferNumber}
          disableDropdown={true}
          disableCountryGuess={true}
          onChange={setNewAgentLiveTransferNumber}
          enableLongNumbers={true}
          specialLabel="Agent number to receive live transfers"
          inputStyle={{
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            height: "56px",
            borderRadius: "4px",
            border: "1px solid #ced4da",
          }}
          required
        />
        <TextField
          margin="dense"
          label="Twilio Account SID"
          type="text"
          fullWidth
          value={newAgentTwilioAccountSID}
          onChange={(e) => setNewAgentTwilioAccountSID(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Twilio Auth Token"
          type="text"
          fullWidth
          value={newAgentTwilioAuthToken}
          onChange={(e) => setNewAgentTwilioAuthToken(e.target.value)}
        />
        <TextField
          margin="dense"
          label="GHL Location ID"
          type="text"
          fullWidth
          value={newAgentGHLLocationID}
          onChange={(e) => setNewAgentGHLLocationID(e.target.value)}
        />
        <TextField
          margin="dense"
          label="GHL Calendar ID"
          type="text"
          fullWidth
          value={newAgentGHLCalendarID}
          onChange={(e) => setNewAgentGHLCalendarID(e.target.value)}
        />
        <TextField
          margin="dense"
          label="GHL API Key"
          type="text"
          fullWidth
          value={newAgentGHLAPIKey}
          onChange={(e) => setNewAgentGHLAPIKey(e.target.value)}
        />
        <TimeZoneSelect
          value={newAgentTimeZone}
          onChange={(e) => setNewAgentTimeZone(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Inbound Webhook"
          type="text"
          fullWidth
          value={newAgentInboundWebhook}
          onChange={(e) => setNewAgentInboundWebhook(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddAgent} color="primary">
          Onboard Agent
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnboardNewAgentDialog;
